<template>
  <div class="branches-grid">
        <h1 v-if="branches.length==0">לא קיימות רשומות עדיין</h1>
        <template v-for="{branche_name,branche_num} in branches" :key="branche_num">
            <div @click="handle_show_branche_summary({branche_name,branche_num})" class="branche">
                <h2>{{branche_name}}</h2>
            </div>
        </template>
  </div>
</template>

<script>
export default {
    props:['branches'],
    emits:['show_branche_summary'],
    setup(_,{emit}){

        const handle_show_branche_summary = (branche) => {
            emit('show_branche_summary',branche)
        }
        return{
            handle_show_branche_summary
        }
    }
}
</script>

<style scoped>
    .branches-grid{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-auto-rows: 150px;
        grid-gap: 5px;
        overflow-y: auto;
        padding: 5px;
    }
    .branche{
        background: var(--secondary);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

    }
</style>